<template>
  <div>
    <page-header
      class="mb-3"
      :title="$t('Terms of participation')"
    />
    <b-row class="d-flex justify-content-center">
      <b-col
        xs="12"
        md="8"
      >
        <b-card>
          <b-card-text>
            <h2 class="mb-2">
              {{ $t('Terms of participation for candidate mentees') }}
            </h2>
            <h4>1. {{ $t('Terms of participation for candidate mentees - 1') }}</h4>
            <p>1.1. {{ $t('Terms of participation for candidate mentees - 1 - p1') }}</p>
            <p>1.2. {{ $t('Terms of participation for candidate mentees - 1 - p2') }}</p>

            <h4>2. {{ $t('Terms of participation for candidate mentees - 2') }}</h4>
            <p>2.1. {{ $t('Terms of participation for candidate mentees - 2 - p1') }}</p>
            <p>2.2. {{ $t('Terms of participation for candidate mentees - 2 - p2') }}<br>
              {{ $t('Terms of participation for candidate mentees - 2 - p2 - a') }}<br>
              {{ $t('Terms of participation for candidate mentees - 2 - p2 - b') }}
            </p>
            <p>2.3. {{ $t('Terms of participation for candidate mentees - 2 - p3') }}</p>

            <h4>3. {{ $t('Terms of participation for candidate mentees - 3') }}</h4>
            <p>3.1. {{ $t('Terms of participation for candidate mentees - 3 - p1') }}</p>
            <p>3.2. {{ $t('Terms of participation for candidate mentees - 3 - p2') }}</p>
            <p>3.3. {{ $t('Terms of participation for candidate mentees - 3 - p3') }}</p>

            <h4>4. {{ $t('Terms of participation for candidate mentees - 4') }}</h4>
            <p>4.1. {{ $t('Terms of participation for candidate mentees - 4 - p1') }}</p>
            <p>4.2. {{ $t('Terms of participation for candidate mentees - 4 - p2') }}</p>
            <p>4.3. {{ $t('Terms of participation for candidate mentees - 4 - p3') }}</p>
            <p>4.4. {{ $t('Terms of participation for candidate mentees - 4 - p4') }}</p>
            <p>4.5. {{ $t('Terms of participation for candidate mentees - 4 - p5') }}<br>
              {{ $t('Terms of participation for candidate mentees - 4 - p5 - a') }}<br>
              {{ $t('Terms of participation for candidate mentees - 4 - p5 - b') }}<br>
              {{ $t('Terms of participation for candidate mentees - 4 - p5 - c') }}
            </p>

            <h4>5. {{ $t('Terms of participation for candidate mentees - 5') }}</h4>
            <p>{{ $t('Terms of participation for candidate mentees - 5 - p1') }}</p>

            <h4>6. {{ $t('Terms of participation for candidate mentees - 6') }}</h4>
            <p>{{ $t('Terms of participation for candidate mentees - 6 - p1') }}</p>

            <h4>7. {{ $t('Terms of participation for candidate mentees - 7') }}</h4>
            <p>{{ $t('Terms of participation for candidate mentees - 7 - p1') }}</p>

            <h4>8. {{ $t('Terms of participation for candidate mentees - 8') }}</h4>
            <p>8.1. {{ $t('Terms of participation for candidate mentees - 8 - p1') }}</p>
            <p>8.2. {{ $t('Terms of participation for candidate mentees - 8 - p2') }}</p>
            <p>8.3. {{ $t('Terms of participation for candidate mentees - 8 - p3') }}</p>

            <h4>9. {{ $t('Terms of participation for candidate mentees - 9') }}</h4>
            <p>9.1. {{ $t('Terms of participation for candidate mentees - 9 - p1') }}</p>
            <p>9.2. {{ $t('Terms of participation for candidate mentees - 9 - p2') }}</p>
            <p>9.3. {{ $t('Terms of participation for candidate mentees - 9 - p3') }}</p>
            <p>9.4. {{ $t('Terms of participation for candidate mentees - 9 - p4') }}</p>
            <p>9.5. {{ $t('Terms of participation for candidate mentees - 9 - p5') }}</p>
            <p>9.6. {{ $t('Terms of participation for candidate mentees - 9 - p6') }}</p>

            <h4>10. {{ $t('Terms of participation for candidate mentees - 10') }}</h4>
            <p>{{ $t('Terms of participation for candidate mentees - 10 - p1') }}</p>
            <p>{{ $t('Terms of participation for candidate mentees - 10 - p2') }}</p>
            <p>{{ $t('Terms of participation for candidate mentees - 10 - p3') }}</p>

            <h2 class="mb-2">
              {{ $t('Terms of participation for candidate mentors') }}
            </h2>
            <h4>1. {{ $t('Terms of participation for candidate mentors - 1') }}</h4>
            <p>1.1. {{ $t('Terms of participation for candidate mentors - 1 - p1') }}</p>
            <p>1.2. {{ $t('Terms of participation for candidate mentors - 1 - p2') }}</p>

            <h4>2. {{ $t('Terms of participation for candidate mentors - 2') }}</h4>
            <p>2.1. {{ $t('Terms of participation for candidate mentors - 2 - p1') }}</p>
            <p>2.2. {{ $t('Terms of participation for candidate mentors - 2 - p2') }}</p>
            <p>2.3. {{ $t('Terms of participation for candidate mentors - 2 - p3') }}</p>
            <p>2.4. {{ $t('Terms of participation for candidate mentors - 2 - p4') }}</p>
            <p>2.5. {{ $t('Terms of participation for candidate mentors - 2 - p5') }}</p>
            <p>2.6. {{ $t('Terms of participation for candidate mentors - 2 - p6') }}</p>

            <h4>3. {{ $t('Terms of participation for candidate mentors - 3') }}</h4>
            <p>3.1. {{ $t('Terms of participation for candidate mentors - 3 - p1') }}</p>
            <p>3.2. {{ $t('Terms of participation for candidate mentors - 3 - p2') }}</p>
            <p>3.3. {{ $t('Terms of participation for candidate mentors - 3 - p3') }}</p>

            <h4>4. {{ $t('Terms of participation for candidate mentors - 4') }}</h4>
            <p>4.1. {{ $t('Terms of participation for candidate mentors - 4 - p1') }}</p>
            <p>4.2. {{ $t('Terms of participation for candidate mentors - 4 - p2') }}</p>
            <p>4.3. {{ $t('Terms of participation for candidate mentors - 4 - p3') }}</p>
            <p>4.4. {{ $t('Terms of participation for candidate mentors - 4 - p4') }}</p>

            <h4>5. {{ $t('Terms of participation for candidate mentors - 5') }}</h4>
            <p>5.1. {{ $t('Terms of participation for candidate mentors - 5 - p1') }}</p>
            <p>5.2. {{ $t('Terms of participation for candidate mentors - 5 - p2') }}</p>
            <p>5.3. {{ $t('Terms of participation for candidate mentors - 5 - p3') }}</p>

            <h4>6. {{ $t('Terms of participation for candidate mentors - 6') }}</h4>
            <p>6.1. {{ $t('Terms of participation for candidate mentors - 6 - p1') }}</p>
            <p>6.2. {{ $t('Terms of participation for candidate mentors - 6 - p2') }}</p>
            <p>6.3. {{ $t('Terms of participation for candidate mentors - 6 - p3') }}</p>
            <p>6.4. {{ $t('Terms of participation for candidate mentors - 6 - p4') }}</p>

            <h2 class="mb-2">
              {{ $t('Terms of participation for candidate coachees') }}
            </h2>
            <h4>1. {{ $t('Terms of participation for candidate coachees - 1') }}</h4>
            <p>1.1. {{ $t('Terms of participation for candidate coachees - 1 - p1') }}</p>
            <p>1.2. {{ $t('Terms of participation for candidate coachees - 1 - p2') }}</p>

            <h4>2. {{ $t('Terms of participation for candidate coachees - 2') }}</h4>
            <p>2.1. {{ $t('Terms of participation for candidate coachees - 2 - p1') }}</p>
            <p>2.2. {{ $t('Terms of participation for candidate coachees - 2 - p2') }}</p>
            <p>2.3. {{ $t('Terms of participation for candidate coachees - 2 - p3') }}</p>

            <h4>3. {{ $t('Terms of participation for candidate coachees - 3') }}</h4>
            <p>3.1. {{ $t('Terms of participation for candidate coachees - 3 - p1') }}</p>
            <p>3.2. {{ $t('Terms of participation for candidate coachees - 3 - p2') }}</p>
            <p>3.3. {{ $t('Terms of participation for candidate coachees - 3 - p3') }}</p>
            <p>3.4. {{ $t('Terms of participation for candidate coachees - 3 - p4') }}</p>
            <p>3.5. {{ $t('Terms of participation for candidate coachees - 3 - p5') }}</p>

            <h4>4. {{ $t('Terms of participation for candidate coachees - 4') }}</h4>
            <p>4.1. {{ $t('Terms of participation for candidate coachees - 4 - p1') }}</p>
            <p>4.2. {{ $t('Terms of participation for candidate coachees - 4 - p2') }}</p>
            <p>4.3. {{ $t('Terms of participation for candidate coachees - 4 - p3') }}</p>

            <h4>5. {{ $t('Terms of participation for candidate coachees - 5') }}</h4>
            <p>5.1. {{ $t('Terms of participation for candidate coachees - 5 - p1') }}</p>
            <p>5.2. {{ $t('Terms of participation for candidate coachees - 5 - p2') }}</p>
            <p>5.3. {{ $t('Terms of participation for candidate coachees - 5 - p3') }}</p>

            <h4>6. {{ $t('Terms of participation for candidate coachees - 6') }}</h4>
            <p>{{ $t('Terms of participation for candidate coachees - 6 - p1') }}</p>

            <h4>7. {{ $t('Terms of participation for candidate coachees - 7') }}</h4>
            <p>7.1. {{ $t('Terms of participation for candidate coachees - 7 - p1') }}</p>
            <p>7.2. {{ $t('Terms of participation for candidate coachees - 7 - p2') }}</p>
            <p>7.3. {{ $t('Terms of participation for candidate coachees - 7 - p3') }}</p>

            <h4>8. {{ $t('Terms of participation for candidate coachees - 8') }}</h4>
            <p>8.1. {{ $t('Terms of participation for candidate coachees - 8 - p1') }}</p>
            <p>8.2. {{ $t('Terms of participation for candidate coachees - 8 - p2') }}</p>
            <p>8.3. {{ $t('Terms of participation for candidate coachees - 8 - p3') }}</p>
            <p>8.4. {{ $t('Terms of participation for candidate coachees - 8 - p4') }}</p>

            <h4>9. {{ $t('Terms of participation for candidate coachees - 9') }}</h4>
            <!-- eslint-disable-next-line -->
            <p v-html="$t('Terms of participation for candidate coachees - 9 - p1')"></p>
            <p>{{ $t('Terms of participation for candidate coachees - 9 - p2') }}</p>
            <p>{{ $t('Terms of participation for candidate coachees - 9 - p3') }}</p>

            <h4>10. {{ $t('Terms of participation for candidate coachees - 10') }}</h4>
            <p>10.1. {{ $t('Terms of participation for candidate coachees - 10 - p1') }}</p>
            <p>10.2. {{ $t('Terms of participation for candidate coachees - 10 - p2') }}</p>

          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
} from 'bootstrap-vue'
import PageHeader from '@mentoring-platform/views/components/blocks/PageHeader.vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    PageHeader,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>
p {
  line-height: 1.8rem;
}
</style>
